import Vue from 'vue'
import {
  Pagination,
  Input,
  Checkbox,
  Table,
  Breadcrumb,
  Form,
  FormItem,
  Icon,
  Row,
  Col,
  Upload,
  Card,
  Cascader,
  Loading,
  MessageBox,
  Message,
  Notification,
  CheckboxGroup,
  TableColumn,
  Button
} from 'element-ui'

Vue.use(Pagination)
Vue.use(CheckboxGroup)
Vue.use(TableColumn)
Vue.use(Button)

Vue.use(Input)

Vue.use(Checkbox)

Vue.use(Table)

Vue.use(Breadcrumb)

Vue.use(Form)
Vue.use(FormItem)
Vue.use(Icon)
Vue.use(Row)
Vue.use(Col)
Vue.use(Upload)

Vue.use(Card)

Vue.use(Cascader)

Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service

Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm

Vue.prototype.$notify = Notification
Vue.prototype.$message = Message

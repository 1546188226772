import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const Index = () => import(/* webpackChunkName: "index" */ '../views/index.vue')
const CompanyList = () =>
  import(/* webpackChunkName: "company-list" */ '../views/companyList.vue')
const Acticle = () =>
  import(/* webpackChunkName: "acticle" */ '../components/acticle.vue')

const Detail = () =>
  import(/* webpackChunkName: "detail" */ '../views/detail.vue')

const forgetRegister = () =>
  import(/* webpackChunkName: "forget" */ '../views/forgetRegister.vue')
const User = () => import(/* webpackChunkName: "user" */ '../views/user.vue')
const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/index',
    redirect: '/'
  },
  {
    path: '/company/:keyword',
    name: 'CompanyList',
    component: CompanyList
  },
  {
    path: '/acticle',
    name: 'Acticle',
    component: Acticle
  },
  {
    path: '/detail',
    name: 'Detail',
    component: Detail
  },
  {
    path: '/forgetRegister',
    name: 'forgetRegister',
    component: forgetRegister
  },
  {
    path: '/user',
    name: 'User',
    component: User,
    children: [
      {
        path: '/',
        redirect: '/user/adminIndex'
      },
      {
        path: '/user/adminIndex',
        name: 'adminIndex',
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */ '../components/user/adminIndex.vue'
          )
      },
      {
        path: '/user/love',
        name: 'Love',
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */ '../components/user/love.vue'
          )
      },
      {
        path: '/user/admin',
        name: 'Admin',
        component: () =>
          import(
            /* webpackChunkName: "group-foo" */ '../components/user/admin.vue'
          ),
        children: [
          {
            path: '/user/admin',
            redirect: '/user/admin/perfect'
          },
          {
            path: '/user/admin/perfect',
            name: 'Perfect',
            component: () =>
              import(
                /* webpackChunkName: "group-admin" */ '../components/user/admin/perfect.vue'
              )
          },
          {
            path: '/user/admin/share',
            name: 'Share',
            component: () =>
              import(
                /* webpackChunkName: "group-admin" */ '../components/user/admin/share.vue'
              )
          }
        ]
      },
      {
        path: '/user/nav',
        name: 'Nav',
        component: () =>
          import(
            /* webpackChunkName: "group-nav" */ '../components/user/nav.vue'
          ),
        children: [
          {
            path: '/user/nav',
            redirect: '/user/nav/navsq'
          },
          {
            path: '/user/nav/navsq',
            name: 'NavSq',
            component: () =>
              import(
                /* webpackChunkName: "group-navsq" */ '../components/user/nav/navSq.vue'
              )
          },
          {
            path: '/user/nav/acticle',
            name: 'ActicleSq',
            component: () =>
              import(
                /* webpackChunkName: "group-acticle" */ '../components/user/nav/acticleSq.vue'
              )
          }
        ]
      }
    ]
  },
  {
    path: '/tool',
    name: 'Tool',
    component: () =>
      import(/* webpackChunkName: "group-tool" */ '../views/tool.vue'),
    children: [
      { path: '/', redirect: '/tool/fob' },
      {
        path: '/tool/fob',
        name: 'Fob',
        component: () =>
          import(
            /* webpackChunkName: "group-tool" */ '../components/Tool/fob.vue'
          )
      },
      {
        path: '/tool/cif',
        name: 'Cif',
        component: () =>
          import(
            /* webpackChunkName: "group-tool" */ '../components/Tool/cif.vue'
          )
      }
    ]
  },
  // 移动端
  {
    path: '/m_index',
    name: 'MIndex',
    component: () =>
      import(/* webpackChunkName: "mobile-index" */ '../mobile/mIndex.vue')
  },
  {
    path: '/m_register',
    name: 'MForgetRegister',
    component: () =>
      import(
        /* webpackChunkName: "mobile-register" */ '../mobile/mForgetRegister.vue'
      )
  },
  {
    path: '/m_company/:keyword',
    name: 'MCompanyList',
    component: () =>
      import(/* webpackChunkName: "mobile-com" */ '../mobile/mCompanyList.vue')
  },
  {
    path: '/m_detail',
    name: 'MDetail',
    component: () =>
      import(/* webpackChunkName: "mobile-detail" */ '../mobile/mDetail.vue')
  },
  {
    path: '/m_acticle',
    name: 'MActicle',
    component: () =>
      import(/* webpackChunkName: "mobile-active" */ '../mobile/mActicle.vue')
  },
  {
    path: '/m_user',
    name: 'MUser',
    component: () =>
      import(/* webpackChunkName: "mobile-user" */ '../mobile/mUser.vue'),
    children: [
      {
        path: '/',
        redirect: '/m_user/m_adminIndex'
      },
      {
        path: '/m_user/m_adminIndex',
        name: 'MAdminIndex',
        component: () =>
          import(
            /* webpackChunkName: "group-mfoo" */ '../mobileComponents/MUser/MAdminIndex.vue'
          )
      },
      {
        path: '/m_user/m_love',
        name: 'MLove',
        component: () =>
          import(
            /* webpackChunkName: "group-mfoo" */ '../mobileComponents/MUser/MIove.vue'
          )
      },
      {
        path: '/m_user/m_admin',
        name: 'MLove',
        component: () =>
          import(
            /* webpackChunkName: "group-mfoo" */ '../mobileComponents/MUser/mAdmin.vue'
          ),
        children: [
          {
            path: '/',
            redirect: '/m_user/m_admin/mm_admin'
          },
          {
            path: '/m_user/m_admin/mm_admin',
            name: 'MAdmin',
            component: () =>
              import(
                /* webpackChunkName: "group-mfoo" */ '../mobileComponents/MUser/admin/mPerfect.vue'
              )
          },
          {
            path: '/m_user/m_admin/m_share',
            name: 'MShare',
            component: () =>
              import(
                /* webpackChunkName: "group-mfoo" */ '../mobileComponents/MUser/admin/mShare.vue'
              )
          }
        ]
      },
      {
        path: '/m_user/m_nav',
        name: 'MNav',
        component: () =>
          import(
            /* webpackChunkName: "group-mnav" */ '../mobileComponents/MUser/mNav.vue'
          ),
        children: [
          {
            path: '/',
            redirect: '/m_user/m_nav/m_navsq'
          },
          {
            path: '/m_user/m_nav/m_navsq',
            name: 'MNavSq',
            component: () =>
              import(
                /* webpackChunkName: "group-mnav" */ '../mobileComponents/MUser/nav/mNavSq.vue'
              )
          },
          {
            path: '/m_user/m_nav/m_acticle',
            name: 'MActicleSq',
            component: () =>
              import(
                /* webpackChunkName: "group-mnav" */ '../mobileComponents/MUser/nav/mActicleSq.vue'
              )
          }
        ]
      }
    ]
  },
  {
    path: '/m_tool',
    name: 'MTool',
    component: () =>
      import(/* webpackChunkName: "mobile-register" */ '../mobile/mTool.vue'),
    children: [
      {
        path: '/',
        name: 'MFob',
        component: () =>
          import(
            /* webpackChunkName: "group-mfoo" */ '../mobileComponents/mTool/mFob.vue'
          )
      },
      {
        path: '/m_tool/m_cif',
        name: 'MCif',
        component: () =>
          import(
            /* webpackChunkName: "group-mfoo" */ '../mobileComponents/mTool/mCif.vue'
          )
      }
    ]
  },
  {
    path: '*',
    name: '404',
    redirect: '/'
  }
]
/**
 * 解决报错问题：报错显示是路由重复
 * Error: Avoided redundant navigation to current location
 */
// 保存push方法
const originalPush = VueRouter.prototype.push
// 重写路由的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router

<template>
  <div id="app" @click="handleClose">
    <router-view></router-view>
  </div>
</template>

<script>
import PubSub from 'pubsub-js'
// eslint-disable-next-line no-unused-vars
import { SoftAccountToken, AddSearchRecord } from './util/ajax'
import { isAccount, _isMobile } from './util/utils'
export default {
  name: 'app',
  methods: {
    // 关闭个人信息中心
    handleClose() {
      PubSub.publish('handleClose', false)
    },
    // url转对象
    queryURLParams(url) {
      const result = {}
      const reg1 = /([^?=&#]+)=([^?=&#]+)/g
      const reg2 = /#([^?=&#]+)/g
      url.replace(reg1, (n, x, y) => (result[x] = y))
      url.replace(reg2, (n, x) => (result.HASH = x))
      return result
    },
    // 判断是否异地登录
    judgmentLogin() {
      const Account = isAccount()
      if (Account) {
        SoftAccountToken({
          mTokenValue: Account.TokenValue,
          mLoginName: decodeURIComponent(Account.tel)
        }).then(res => {
          if (res === 0) {
            this.$alert('您的账户在别处登录,点击确定退出', '提示', {
              confirmButtonText: '确定',
              callback: action => {
                window.localStorage.clear()
                window.sessionStorage.clear()
                if (_isMobile()) {
                  this.$router.push('/m_index')
                } else {
                  this.$router.push('/index')
                }

                window.location.reload()
              },
              showClose: false
            })
          }
        })
      }
    }
  },
  created() {
    // 判断是否手机端
    if (_isMobile()) {
      const reg = /^\/m_/
      const reg2 = /^\/tool/
      const reg3 = /^\/acticle/
      const pathname = window.location.pathname

      // 判断是否手机端的网页, 如果是的话，则不跳转
      if (!reg.test(pathname) && !reg2.test(pathname) && !reg3.test(pathname)) {
        this.$router.replace('/m_index')
      }
    }
    document.onkeydown = function (e) {
      // eslint-disable-next-line yoda
      if (68 === e.keyCode && e.ctrlKey) {
        localStorage.setItem('collect', '1')
        PubSub.publish('collect', '1')
      }
    }
    if (process.env.NODE_ENV !== 'development') {
      if (
        window.location.protocol === 'http:' &&
        window.location.hostname !== '192.168.1.40'
      ) {
        window.location.href = 'https://www.smtso.cn'
      }
    }
  },
  mounted() {
    // 判断是否异地登录
    this.judgmentLogin()
    // 获取且存储邀请码
    const iSmtsoCode = this.queryURLParams(location.search).i
    if (iSmtsoCode) {
      localStorage.setItem('iSmtsoCode', iSmtsoCode)
    }
  }
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
</style>

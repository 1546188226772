import axios from 'axios'
// console.log()
let origin = 'http://localhost:5501/smtsoapi'
if (process.env.NODE_ENV !== 'development') {
  origin = window.location.origin
}
// 创建axios的实例
const $axios = axios.create({
  // baseURL: 'http://www.smtsousou.com',
  baseURL: origin,
  // baseURL: 'http://api.smtso.cn/smtsousou',
  // baseURL: 'http://192.168.1.35:8287/smtsousou', // 初始化路径
  // baseURL: 'http://localhost:5501/smtsoapi',

  timeout: 1000 * 60
  // withCredentials: true
})

// 请求拦截器
$axios.interceptors.request.use(config => {
  console.log(config)
  return config
})

// 响应拦截器
$axios.interceptors.response.use(response => {
  return response.data
})

export default function ajax(url, data = {}, type = 'POST', body) {
  return new Promise(function (resolve, reject) {
    let promise
    type = type.toUpperCase()
    let dataStr = ''
    if (JSON.stringify(data) !== '{}') {
      Object.keys(data).forEach(key => {
        dataStr += key + '=' + data[key] + '&'
      })
    }

    let newURl = url
    if (dataStr !== '') {
      dataStr = dataStr.substring(0, dataStr.lastIndexOf('&'))
      newURl += '?' + dataStr
    }
    if (type === 'GET') {
      promise = $axios.get(newURl)
    } else {
      console.log(data)
      if (body === 'body') {
        promise = $axios.post(url, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
      } else {
        promise = $axios.post(newURl)
      }
    }
    promise
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

// 判断是否移动端
export function _isMobile() {
  const flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
  return flag
}

// 是否登录
export function isAccount() {
  const Account = window.sessionStorage.getItem('Account')
  if (Account) {
    return JSON.parse(Account)
  } else {
    return false
  }
}

// 获取localStrage的值
export function get(key) {
  var data = localStorage.getItem(key)
  if (!data || data === 'null' || data === 'undefined') {
    return 0
  }
  var dataObj = JSON.parse(data)

  if (Object.prototype.toString.call(dataObj) !== '[object Object]') {
    return 0
  }
  const curTime = new Date().getTime()
  if (curTime > dataObj.nextTime) {
    localStorage.removeItem(key)
    return 0
  } else {
    return dataObj.data
  }
}

// 设置localStrage的有效时间
export function set(key, v) {
  const data = new Date()
  const curTime = data.getTime()
  // 小时
  let hours = data.getHours()
  hours = hours * 60 * 60 * 1000
  // 分钟
  let minutes = data.getMinutes()
  minutes = minutes * 60 * 1000
  // 秒
  let seconds = data.getSeconds()
  seconds = seconds * 1000
  // 毫秒
  const milliseconds = data.getMilliseconds()
  // 当天0点的毫秒值
  const T = curTime - hours - minutes - seconds - milliseconds
  // 第二天0点的毫秒值
  const nextTime = T + 24 * 60 * 60 * 1000
  localStorage.setItem(
    key,
    JSON.stringify({
      data: v,
      time: curTime,
      nextTime
    })
  )
}

// 出来时间格式
export function dateFormat(date, format) {
  if (typeof date === 'string') {
    var mts = date.match(/(\/Date\((\d+)\)\/)/)
    if (mts && mts.length >= 3) {
      date = parseInt(mts[2])
    }
  }
  date = new Date(date)
  // eslint-disable-next-line eqeqeq
  if (!date || date.toUTCString() == 'Invalid Date') {
    return ''
  }
  var map = {
    M: date.getMonth() + 1, // 月份
    d: date.getDate(), // 日
    h: date.getHours(), // 小时
    m: date.getMinutes(), // 分
    s: date.getSeconds(), // 秒
    q: Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds() // 毫秒
  }

  format = format.replace(/([yMdhmsqS])+/g, function(all, t) {
    var v = map[t]
    if (v !== undefined) {
      if (all.length > 1) {
        v = '0' + v
        v = v.substr(v.length - 2)
      }
      return v
    } else if (t === 'y') {
      return (date.getFullYear() + '').substr(4 - all.length)
    }
    return all
  })
  return format
}

// url转对象
export function queryURLParams(url) {
  const result = {}
  const reg1 = /([^?=&#]+)=([^?=&#]+)/g
  const reg2 = /#([^?=&#]+)/g
  url.replace(reg1, (n, x, y) => (result[x] = y))
  url.replace(reg2, (n, x) => (result.HASH = x))
  return result
}
// 统一url路径
export function CompanyURLFc(url) {
  if (url === '') {
    return url
  }
  const reg = /http/
  const reg2 = /www./
  const reg3 = /https/
  if (!reg.test(url)) {
    url = 'http://' + url
  }
  if (!reg2.test(url)) {
    if (reg3.test(url)) {
      url = url.replace('https://', 'https://www.')
    } else {
      url = url.replace('http://', 'http://www.')
    }
  }
  return url
}

// 统一title
export function CompanyTitleFc(Title) {
  const reg = /(\sCo\.,Ltd\.\s)|(\sLtd\s)|(\sSDN\s)|(\sBHD\s)|(\sGmbH\s)|(\sAG\s)|(\sS\.A\.\s)|(\sS\.A\.R\.L\.\s)|(\sB\.V\.\s)|(\sN\.V\.\s)|(\sA\/S\s)|(\sS\.P\.A\.\s)|(\sS\.R\.L\.\s)|(\sPLC\s)|(\sAB\s)|(\sOY\s)|(\sK\.K\.\s)|(\sY\.K\.\s)/
  const reg2 = /(\s\|\s)/
  const ary1 = reg.exec(Title)
  if (ary1) {
    Title = Title.substr(0, ary1.index)
  }
  const ary2 = reg2.exec(Title)
  if (ary2) {
    Title = Title.substr(0, ary2.index)
  }

  return Title
}

// 根据分数计算可以干嘛
export function Regulation(integral) {}

// 给判断是否域名使用的
function getHost(url) {
  var host = 'null'
  if (typeof url === 'undefined' || url === null) {
    return ''
  }
  if (url.indexOf('http') !== -1) {
    // eslint-disable-next-line no-useless-escape
    var regex = /.*\:\/\/([^\/]*).*/
    var match = url.match(regex)
    if (typeof match !== 'undefined' && match !== null) host = match[1]
  } else {
    // eslint-disable-next-line no-useless-escape
    const regex = /([^\/]*).*/
    // eslint-disable-next-line no-useless-escape
    const match = url.match(regex)
    // eslint-disable-next-line no-useless-escape
    if (typeof match !== 'undefined' && match !== null) host = match[1]
  }
  if (host.indexOf('www.') !== -1) {
    host = host.replace('www.', '')
  }
  return host
}

// 判断是否域名
export function CheckUrl(url) {
  try {
    url = getHost(url)
    const reg = /[-A-Za-z0-9_.]+(\.aero|\.ad|\.ae|\.af|\.ag|\.ai|\.al|\.am|\.an|\.ao|\.aq|\.ar|\.as|\.at|\.au|\.aw|\.az|\.biz|\.ba|\.bb|\.bd|\.be|\.bf|\.bg|\.bh|\.bi|\.bj|\.bm|\.bn|\.bo|\.br|\.bs|\.bt|\.bv|\.bw|\.by|\.bz|\.coop|\.com|\.ca|\.cc|\.cf|\.cg|\.ch|\.ci|\.ck|\.cl|\.cm|\.cn|\.co|\.cq|\.cr|\.cu|\.cv|\.cx|\.cy|\.cz|\.de|\.dj|\.dk|\.dm|\.do|\.dz|\.edu|\.ec|\.ee|\.eg|\.eh|\.es|\.et|\.ev|\.fi|\.fj|\.fk|\.fm|\.fo|\.fr|\.gov|\.ga|\.gb|\.gd|\.ge|\.gf|\.gh|\.gi|\.gl|\.gm|\.gn|\.gp|\.gr|\.gt|\.gu|\.gw|\.gy|\.hk|\.hm|\.hn|\.hr|\.ht|\.hu|\.info|\.ink|\.int|\.id|\.ie|\.il|\.in|\.io|\.iq|\.ir|\.is|\.it|\.jm|\.jo|\.jp|\.ke|\.kg|\.kh|\.ki|\.km|\.kn|\.kp|\.kr|\.kw|\.ky|\.kz|\.la|\.lb|\.lc|\.li|\.lk|\.lr|\.ls|\.lt|\.lu|\.lv|\.ly|\.museum|\.mobi|\.mil|\.ma|\.mc|\.md|\.mg|\.mh|\.ml|\.mm|\.mn|\.mo|\.mp|\.mq|\.mr|\.ms|\.mt|\.mv|\.mw|\.mx|\.my|\.mz|\.name|\.net|\.na|\.nc|\.ne|\.nf|\.ng|\.ni|\.nl|\.no|\.np|\.nr|\.nt|\.nu|\.nz|\.org|\.om|\.pro|\.pub|\.pa|\.pe|\.pf|\.pg|\.ph|\.pk|\.pl|\.pm|\.pn|\.pr|\.pt|\.pw|\.py|\.qa|\.red|\.re|\.ro|\.ru|\.rw|\.sa|\.sb|\.sc|\.sd|\.se|\.sg|\.sh|\.si|\.sj|\.sk|\.sl|\.sm|\.sn|\.so|\.sr|\.st|\.su|\.sy|\.sz|\.travel|\.tech|\.top|\.tc|\.td|\.tf|\.tg|\.th|\.tj|\.tk|\.tm|\.tn|\.to|\.tp|\.tr|\.tt|\.tv|\.tw|\.tz|\.ua|\.ug|\.uk|\.us|\.uy|\.va|\.vc|\.ve|\.vg|\.vn|\.vu|\.wf|\.ws|\.xyz|\.ye|\.yu|\.za|\.zm|\.zr|\.zw)$/ // 域名验证表达式

    if (!reg.test(url)) {
      return false
    } else {
      return true
    }
  } catch (e) {
    // TODO handle the exception
    return false
  }
}

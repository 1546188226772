import ajax from './request'
// import ajax2 from './request2'
// import { Base64 } from 'js-base64'
// 注册发送短信接口
export function getDxCodeAjax(params) {
  params.loginName = EncodeStrXor(params.loginName)
  params.vCode = EncodeStrXor(params.vCode)
  return new Promise((resolve, reject) => {
    ajax('/sendSmsVCode_reg', params, 'GET')
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
// 注册接口
export function register(params) {
  params.loginName = EncodeStrXor(params.loginName)
  params.password = EncodeStrXor(params.password)
  params.vCode = EncodeStrXor(params.vCode)
  params.invitationCode = EncodeStrXor(params.invitationCode)
  return new Promise((resolve, reject) => {
    ajax('/register', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
// 忘记密码发送短信接口
export function getForgetPwdDx(params) {
  params.loginName = EncodeStrXor(params.loginName)
  params.vCode = EncodeStrXor(params.vCode)
  return new Promise((resolve, reject) => {
    ajax('/sendSmsVCode_forget', params, 'GET')
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
// 忘记密码接口
export function forgetPwd(params) {
  for (const key in params) {
    params[key] = EncodeStrXor(params[key])
  }
  return new Promise((resolve, reject) => {
    ajax('/forget', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
// 账号密码登录接口
export function SmartSouSouLogin(params) {
  // for (const key in params) {
  //   params[key] = EncodeStrXor(params[key]))
  // }
  for (const key in params) {
    params[key] = EncodeStrXor(params[key])
  }
  return new Promise((resolve, reject) => {
    ajax('/login', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
// 手机验证码登录接口
export function LoginByNum(params) {
  params.mSoftSystemTag = 'SMTSOUSOU'
  for (const key in params) {
    params[key] = EncodeStrXor(params[key])
  }
  return new Promise((resolve, reject) => {
    ajax('/loginByNum', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
// export function loginAjax(params) {
//   for (const key in params) {
//     console.log(key)
//     params[key] = EncodeStrXor(params[key]))
//   }
//   return new Promise((resolve, reject) => {
//     ajax('/login', params)
//       .then(res => {
//         resolve(res)
//       })
//       .catch(err => {
//         reject(err)
//       })
//   })
// }
// 登录接口2
// export function SmartSouSouLogin(params) {
//   return new Promise((resolve, reject) => {
//     ajax2('/SmartSouSouLoginCOS', params)
//       .then(res => {
//         resolve(res)
//       })
//       .catch(err => {
//         reject(err)
//       })
//   })
// }

// 判断是否被其他设备登录

export function SoftAccountToken(params) {
  params.mTokenValue = EncodeStrXor(params.mTokenValue)
  params.mLoginName = EncodeStrXor(params.mLoginName)
  return new Promise((resolve, reject) => {
    ajax('/VerifySoftAccountToken', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 根据公司名称/域名获取公司详细信息
export function getCompanyDetail(params) {
  params.keyword = EncodeStrXor(params.keyword)
  return new Promise((resolve, reject) => {
    ajax('/getCompanyInfoByKeyword', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 搜索关键词接口
export function searchKey(params) {
  // 加密
  params.Keyword = EncodeStrXor(params.Keyword)
  return new Promise((resolve, reject) => {
    ajax('/crossGetCompanyList', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 根据公司名称获取贸易国家
export function getTrade(params) {
  params.Name = EncodeStrXor(params.Name)
  return new Promise((resolve, reject) => {
    ajax('/crossMYGJ', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
// 根据公司名称获取交易伙伴
export function getJYHB(params) {
  params.Name = EncodeStrXor(params.Name)
  return new Promise((resolve, reject) => {
    ajax('/crossJYHB', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 根据公司名称获取交易产品
export function getJYCP(params) {
  params.Name = EncodeStrXor(params.Name)
  return new Promise((resolve, reject) => {
    ajax('/crossJYCP', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 根据公司名称获取年度贸易分析
export function getMYFX(params) {
  params.Name = EncodeStrXor(params.Name)
  return new Promise((resolve, reject) => {
    ajax('/crossNDMY', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 根据keyword获取商机推荐列表
export function getRecommend(params) {
  params.Keyword = EncodeStrXor(params.Keyword)
  params.Title = EncodeStrXor(params.Title)
  return new Promise((resolve, reject) => {
    ajax('/crossSJTJ', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 获取外贸导航列表
export function getNavList() {
  return new Promise((resolve, reject) => {
    ajax('/crossGetNavigationURLList')
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 根据关键字获取Bing搜索引擎
export function getBind(params) {
  params.keyword = EncodeStrXor(params.keyword)
  return new Promise((resolve, reject) => {
    ajax('/getSearchEngineData', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 获取 ip
export function getIp() {
  return new Promise((resolve, reject) => {
    ajax('/crossGetIP')
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
// 新增搜索记录
export function AddSearchRecord(params) {
  console.log('params', params.Keyword)
  params.AccountCode = EncodeStrXor(params.AccountCode)
  return new Promise((resolve, reject) => {
    ajax('/crossAddSearchHistory', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 根据用户code获取当天消耗搜索次数
export function GetCodeCount(params) {
  params.AccountCode = EncodeStrXor(params.AccountCode)
  return new Promise((resolve, reject) => {
    ajax('/crossGetSearchHistoryCount', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 新增导出记录
export function AddDeriveRecord(params) {
  return new Promise((resolve, reject) => {
    ajax('/crossAddExportHistory', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
// 根据用户code获取当天消耗导出条数
export function GetExtportHistoryCount(params) {
  params.AccountCode = EncodeStrXor(params.AccountCode)
  return new Promise((resolve, reject) => {
    ajax('/crossGetExtportHistoryCount', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 新增用户积分记录
export function AddAccountIntegral(params) {
  params.AccountCode = EncodeStrXor(params.AccountCode)
  params.Source = 'smtso'
  return new Promise((resolve, reject) => {
    ajax('/crossAddAccountIntegral', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 根据用户code获取用户积分
export function GetAccountIntegral(params) {
  params.AccountCode = EncodeStrXor(params.AccountCode)
  return new Promise((resolve, reject) => {
    ajax('/crossGetAccountIntegral', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 根据用户code添加到收藏
export function AddCollection(params) {
  params.AccountCode = EncodeStrXor(params.AccountCode)
  return new Promise((resolve, reject) => {
    ajax('/crossAddCollection', JSON.stringify(params), 'POST', 'body')
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 根据用户code获取收藏列表
export function GetCollection(params) {
  params.AccountCode = EncodeStrXor(params.AccountCode)
  return new Promise((resolve, reject) => {
    ajax('/crossGetCollection', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 根据收藏code取消收藏
export function CancelCollection(params) {
  params.CollectionCode = EncodeStrXor(params.CollectionCode)
  return new Promise((resolve, reject) => {
    ajax('/crossCancelCollection', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
// 根据用户code和Domain判断该用户是否收藏过
export function CheckCollection(params) {
  params.AccountCode = EncodeStrXor(params.AccountCode)
  return new Promise((resolve, reject) => {
    ajax('/crossCheckCollection', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 查询用户排名
export function GetPerAccountIntegral(params) {
  params.AccountCode = EncodeStrXor(params.AccountCode)
  return new Promise((resolve, reject) => {
    ajax('/crossGetPerAccountIntegral', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 根据用户code编辑个人信息
export function EditUserInfo(params) {
  params.AccountCode = EncodeStrXor(params.AccountCode)
  return new Promise((resolve, reject) => {
    ajax('/crossEditUserInfo', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
// 根据用户code获取用户信息
export function GetUserInfo(params) {
  params.AccountCode = EncodeStrXor(params.AccountCode)
  return new Promise((resolve, reject) => {
    ajax('/crossGetUserInfo', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
// 根据用户code获取邀请码
export function GetInvitationCode(params) {
  params.AccountCode = EncodeStrXor(params.AccountCode)
  return new Promise((resolve, reject) => {
    ajax('/crossGetInvitationCode', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 用户提交导航链接
export function SubmitNav(params) {
  params.AccountCode = EncodeStrXor(params.AccountCode)
  return new Promise((resolve, reject) => {
    ajax('/crossSubmitNav', JSON.stringify(params), 'POST', 'body')
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
// 用户提交文章
export function SubmitArticle(params) {
  params.AccountCode = EncodeStrXor(params.AccountCode)
  return new Promise((resolve, reject) => {
    ajax('/crossSubmitArticle', params, 'POST', 'body')
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
// 根据ID获取文章
export function GetArticle(params) {
  return new Promise((resolve, reject) => {
    ajax('/crossGetArticle', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
// 获取热搜关键词(统计前十条)
export function GetHotK(params) {
  return new Promise((resolve, reject) => {
    ajax('/crossGetHotK', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
// 根据用户code获取用户提交导航链接记录
export function GetSubmitURL(params) {
  params.AccountCode = EncodeStrXor(params.AccountCode)
  return new Promise((resolve, reject) => {
    ajax('/crossGetSubmitURL', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 根据用户code获取用户文章列表
export function GetArticleList(params) {
  params.AccountCode = EncodeStrXor(params.AccountCode)
  return new Promise((resolve, reject) => {
    ajax('/crossGetArticleList', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
// 根据用户code新增用户自定义导航链接
export function AddCustomerNav(params) {
  params.AccountCode = EncodeStrXor(params.AccountCode)
  return new Promise((resolve, reject) => {
    ajax('/crossAddCustomerNav', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
// 根据CustomizeNavigationURLCode删除自定义导航链接
export function DelCustomerNav(params) {
  return new Promise((resolve, reject) => {
    ajax('/crossDelCustomerNav', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
// 根据用户code获取用户自定义导航链接列表
export function GetCustomerNav(params) {
  params.AccountCode = EncodeStrXor(params.AccountCode)
  return new Promise((resolve, reject) => {
    ajax('/crossGetCustomerNav', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 根据域名获取列表信息
export function GetCompany(params) {
  params.Domain = EncodeStrXor(params.Domain)
  return new Promise((resolve, reject) => {
    ajax('/crossGetCompany', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 新增用户签到记录
export function AddSign(params) {
  params.AccountCode = EncodeStrXor(params.AccountCode)
  return new Promise((resolve, reject) => {
    ajax('/crossAddSign', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 根据用户code查询用户当天是否已签到
export function CheckSign(params) {
  params.AccountCode = EncodeStrXor(params.AccountCode)
  return new Promise((resolve, reject) => {
    ajax('/crossCheckSign', params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 获取所有州信息
export function AreaGetAll(params) {
  return new Promise((resolve, reject) => {
    ajax(
      'https://tpis.smartsousou.com/TPDataDictionary/Api/Area/GetAllByCORS',
      params
    )
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
// 根据指定州代码获取州下所有国家信息:
export function GetListByArea(params) {
  return new Promise((resolve, reject) => {
    ajax(
      'https://tpis.smartsousou.com/TPDataDictionary/Api/Country/GetListByAreaByCORS',
      params
    )
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 获取所有州跟国家信息:
export function GetCountryList(params) {
  return new Promise((resolve, reject) => {
    ajax(
      'https://tpis.smartsousou.com/TPDataDictionary/Api/Country/GetAllByCORSGroup',
      params
    )
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'

import 'vue-area-linkage/dist/index.css' // v2 or higher
import VueAreaLinkage from 'vue-area-linkage'
import './util/flexible'
import './assets/css/global.css'
import VueClipboard from 'vue-clipboard2'

Vue.config.productionTip = false
// import ajax from './util/ajax'
// Vue.prototype.$ajax = ajax
Vue.prototype.$GJ = 'web'

Vue.prototype.$echarts = window.echarts
Vue.use(VueClipboard)

Vue.use(VueAreaLinkage)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
